import React, { useReducer, useContext } from "react";
import axios from "axios";
import moment from "moment";
import { get } from "lodash";
import LeadContext from "./leadContext";
import LeadReducer from "./LeadReducer";
import {
    SET_LOADING,
    GET_LEAD_COUNTS,
    GET_LEAD_DEALERS,
    GET_DEALER_LEADS,
    GET_LEADS,
    GET_LEAD,
    GET_LEAD_PAGE,
    LEAD_STATUS_UPDATED,
    SET_ACTIVE_PAGE_VALUE,
    SET_LEAD_DEALERS_PAGE,
    SET_DEALER_LEADS_PAGE,
    SET_LEAD_PAGE,
    SET_LEAD_FILTER_VALUE
} from "../types";
import SearchContext from "../search/searchContext";
import { checkLeadFilter } from "../../tables/leadFilterOptions";
import { replaceHyphens, checkFilter } from "../../utils/utils";

const
    makeVehicleDetail = lead => {
    let status = !!(lead.vehicle_registration || lead.make || lead.model);

    const leadState =  {
        title: "Vehicle Details",
        info: [
            {
                name: "Registration",
                value: lead.vehicle_registration
                    ? lead.vehicle_registration
                    : "Not available"
            },
            {
                name: "Make",
                value: lead.make ? lead.make : "Not available"
            },
            {
                name: "Model",
                value: lead.model ? lead.model : "Not available"
            }
        ],
        status: { status }
    };

    if (lead.audatex_user) {

        leadState.info.push({
            name: "Vin",
            value: lead.vin
                ? lead.vin
                : "Not available"
        })

    }

    return leadState

};

const makeCustomerDetail = lead => {
    let status = true;
    return {
        title: "Customer Details",
        info: [
            {
                name: "Centre",
                value: lead.centre_name ? lead.centre_name : "Not available"
            },
            {
                name: "Centre Reference",
                value: lead.centre_ref ? lead.centre_ref : "Not available"
            },
            {
                name: "Distrigo ID",
                value: lead.customer_trade_team_id
                    ? lead.customer_trade_team_id
                    : "Not available"
            },
            {
                name: "Contact Name",
                value: lead.customer_name ? lead.customer_name : "Not available"
            },
            {
                name: "Contact Address",
                value: lead.customer_address
                    ? lead.customer_address
                    : "Not available"
            },
            {
                name: "Contact Phone",
                value: lead.customer_phone
                    ? lead.customer_phone
                    : "Not available"
            },
            {
                name: "Contact Email",
                value: lead.customer_email
                    ? lead.customer_email
                    : "Not available"
            }
        ],
        status: { status }
    };
};

const makeJobDetail = lead => {
    let status = true;
    return {
        title: "Job Details",
        info: [
            {
                name: "Job Reference",
                value: lead.job_ref ? lead.job_ref : "Not available"
            },
            {
                name: "Confirmation Date",
                value: lead.confirmation_date
                    ? moment(lead.confirmation_date).format("DD/MM/YYYY HH:mm")
                    : "Not available"
            },
            {
                name: "Lead Creation",
                value: lead.created_at
                    ? moment(lead.created_at).format("DD/MM/YYYY HH:mm")
                    : "Not available"
            },
            {
                name: "Appointment Date",
                value: lead.appointment_at
                    ? moment(lead.appointment_at).format("DD/MM/YYYY HH:mm")
                    : "Not available"
            },
            {
                name: "Job Description",
                value: lead.job_description
                    ? lead.job_description
                    : "Not available"
            },
            {
                name: "Job Information",
                value: lead.job_information
                    ? lead.job_information
                    : "Not available"
            },
            {
                name: "Source",
                value: lead.source ? lead.source : "Not available"
            },
            {
                name: "Followed Up At",
                value: lead.followed_up_date
                    ? moment(lead.followed_up_date).format("DD/MM/YYYY HH:mm")
                    : "-"
            },
            {
                name: "Sale Status",
                value: lead.display_sale_status ? lead.display_sale_status : "-"
            },
            {
                name: "Invoice",
                value: lead.invoice ? lead.invoice : "-"
            }
        ],
        status: { status }
    };
};

const makeBasketDetail = lead => {
    return {
        title: "Basket Details",
        info: lead.basket_items,
        total_price: lead.total_price
    };
};

const LeadState = props => {
    const searchContext = useContext(SearchContext);
    const { startDateMySQL, endDateMySQL, paginationValue } = searchContext;

    const initialState = {
        lead: {},
        leadLinks: {},
        leadCount: 0,
        leadDealerCount: 0,
        leadCounts: [],
        leads: [],
        leadDealers: [],
        loading: false,
        leadFilterValue: "all",
        activePage: 1,
        leadsPage: 1,
        dealerLeadsPage: 1,
        leadDealersPage: 1
    };

    const [state, dispatch] = useReducer(LeadReducer, initialState);

    /**
     * @param {string} filter
     * @return {undefined}
     */
    const setLeadFilterValue = filter => {
        dispatch({
            type: SET_LEAD_FILTER_VALUE,
            payload: filter
        });
    };

    /**
     * Ensure the filter value passed is a leadFilter
     * if not, set default filter instead
     *
     * @param {string} rawFilter
     * @return {undefined}
     */
    const setLeadFilterOrDefault = rawFilter => {
        const filter = checkLeadFilter(replaceHyphens(rawFilter));
        setLeadFilterValue(filter);
    };

    // deprecated?
    const setActivePage = pageNumber => {
        dispatch({
            type: SET_ACTIVE_PAGE_VALUE,
            payload: pageNumber
        });
    };

    /**
     * @param {integer} pageNumber
     * @return {undefined}
     */
    const setLeadPage = pageNumber => {
        dispatch({
            type: SET_LEAD_PAGE,
            payload: pageNumber
        });
    };

    /**
     * @param {integer} pageNumber
     * @return {undefined}
     */
    const setDealerLeadsPage = pageNumber => {
        dispatch({
            type: SET_DEALER_LEADS_PAGE,
            payload: pageNumber
        });
    };

    /**
     * @param {integer} pageNumber
     * @return {undefined}
     */
    const setLeadDealersPage = pageNumber => {
        dispatch({
            type: SET_LEAD_DEALERS_PAGE,
            payload: pageNumber
        });
    };

    const updateLeadStatus = async values => {
        const res = await axios.post(`/api/v1/lead/status-update`, values);
        const lead = get(res, "data.data", {});

        getLeads({
            leadFilterValue: "all_dates",
            start: startDateMySQL,
            end: endDateMySQL
        });

        const data = {
            data: lead,
            jobDetail: makeJobDetail(lead),
            vehicleDetail: makeVehicleDetail(lead),
            customerDetail: makeCustomerDetail(lead),
            basketDetail: makeBasketDetail(lead)
        };
        dispatch({
            type: LEAD_STATUS_UPDATED,
            payload: data
        });

        // dispatch({
        // type: LEAD_STATUS_UPDATED,
        // payload: get(res, "data", {})
        // });
    };

    const getLeadCounts = async () => {
        setLoading();
        const res = await axios.get(
            `/api/v1/lead/counts/${startDateMySQL}/${endDateMySQL}`
        );
        dispatch({
            type: GET_LEAD_COUNTS,
            payload: get(res, "data", {})
        });
    };

    const getLeadDealers = async ({
        leadFilterValue: leadFilterValueRaw,
        start,
        end
    }) => {
        const filter = replaceHyphens(leadFilterValueRaw);

        setLoading();
        const res = await axios.get(
            `/api/v1/lead-dealers/${filter}/${start}/${end}?pagination=${paginationValue}&page=${state.leadDealersPage}`
        );
        dispatch({
            type: GET_LEAD_DEALERS,
            payload: get(res, "data", {})
        });
    };

    /**
     * param {string}   leadFilterValue (object prop)
     * param {integer}  id (object prop)
     * return {undefined}
     */
    const getDealerLeads = async ({
        leadFilterValue: leadFilterValueRaw,
        id
    }) => {
        const filter = replaceHyphens(leadFilterValueRaw);

        setLoading();
        const res = await axios.get(
            `/api/v1/lead/dealer/${id}/${filter}/${startDateMySQL}/${endDateMySQL}?pagination=${paginationValue}&page=${state.dealerLeadsPage}`
        );

        dispatch({
            type: GET_DEALER_LEADS,
            payload: get(res, "data", {})
        });
    };

    /**
     * param {string} leadFilterValue (object prop)
     * return {undefined}
     */
    const getLeads = async ({
        leadFilterValue: leadFilterValueRaw,
        start,
        end
    }) => {
        const filter = replaceHyphens(leadFilterValueRaw);

        setLoading();
        const res = await axios.get(
            `/api/v1/leads/${filter}/${start}/${end}?pagination=${paginationValue}&page=${state.leadsPage}`
        );
        dispatch({
            type: GET_LEADS,
            payload: get(res, "data", {})
        });
    };

    /**
     * param {integer} pageNumber
     * return {undefined}
     */
    const getLeadPage = async pageNumber => {
        setLoading();
        const res = await axios.get(
            `/api/v1/leads/${startDateMySQL}/${endDateMySQL}?page=${pageNumber}`
        );
        dispatch({
            type: GET_LEAD_PAGE,
            payload: get(res, "data", {})
        });
    };

    /**
     * param {integer} id
     * return {undefined}
     */
    const getLead = async id => {
        setLoading();
        const res = await axios.get(`/api/v1/lead/${id}`);
        const lead = res.data.data;

        const data = {
            data: lead,
            jobDetail: makeJobDetail(lead),
            vehicleDetail: makeVehicleDetail(lead),
            customerDetail: makeCustomerDetail(lead),
            basketDetail: makeBasketDetail(lead)
        };
        dispatch({
            type: GET_LEAD,
            payload: data
        });
    };

    // Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING });
    return (
        <LeadContext.Provider
            value={{
                lead: state.lead,
                leads: state.leads,
                leadDealers: state.leadDealers,
                leadLinks: state.leadLinks,
                leadCount: state.leadCount,
                leadCounts: state.leadCounts,
                leadDealerCount: state.leadDealerCount,
                loading: state.loading,
                activePage: state.activePage, // deprecated?
                leadsPage: state.leadsPage,
                dealerLeadsPage: state.dealerLeadsPage,
                leadDealersPage: state.leadDealersPage,
                leadFilterValue: state.leadFilterValue,
                setLeadPage,
                setLeadFilterValue,
                setLeadFilterOrDefault,
                setDealerLeadsPage,
                setLeadDealersPage,
                setActivePage,
                getLeadPage,
                setLoading,
                // getTotalLeads,
                getLeads,
                getLeadCounts,
                getLeadDealers,
                getDealerLeads,
                getLead,
                updateLeadStatus
            }}
        >
            {props.children}
        </LeadContext.Provider>
    );
};

export default LeadState;
